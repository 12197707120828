import { useWeb3React } from "@web3-react/core";
import ConnectModal from "components/connectModal/ConnectModal";
import React, { useEffect, useState } from "react";
import { truncateWalletString } from "utils";
import { connectorLocalStorageKey, connectors } from "utils/connectors";
import "./Default.css";

function Navbar() {
  const { connector, library, account, active, chainId, deactivate } =
    useWeb3React();
  const [loginStatus, setLoginStatus] = useState(false);
  let newVariable = process.env.REACT_APP_NETWORK_ID;
  useEffect(() => {
    const isLoggedin =
      account && active && chainId === parseInt(newVariable, 10);
    setLoginStatus(isLoggedin);
  }, [connector, library, account, active, chainId, newVariable]);

  const signOut = () => {
    connectors.map((connector) => {
      deactivate();
    });
    window.localStorage.setItem(connectorLocalStorageKey, "");
    window.location.href = "/";
  };
  const [showConnectModal, setShowConnectModal] = useState(false);

  return (
    <div className="navbar-container">
      <div className="container">
        <div className="d-flex flex-wrap justify-content-center justify-content-sm-between justify-content-md-between align-items-center">
          <div className="brand-logo">
            <a href="https://epicmtv.com">
              <img
                src="/logo.png"
                alt="LazyMint"
                className="img-fluid epic-gallery"
              />
            </a>
          </div>
          <div className="btn-container d-flex justify-content-between align-items-center">
            <div className="collect-wallet-btn-container ms-0 ms-sm-5 ms-md-0">
              <a className="btn connect-wallet-btn" href="https://epicmtv.com">
                Home
              </a>
            </div>
            <li></li>
          </div>
          <div className="btn-container d-flex justify-content-between align-items-center">
            <div className="collect-wallet-btn-container ms-0 ms-sm-5 ms-md-0">
              <button
                className="btn connect-wallet-btn"
                disabled={loginStatus}
                onClick={() => setShowConnectModal(true)}
              >
                {!loginStatus
                  ? "CONNECT WALLET"
                  : truncateWalletString(account)}
              </button>
            </div>
            <div className="signout-btn ms-3">
              {loginStatus && (
                <div className="connect-wallet-btn-container">
                  <div
                    className="btn connect-wallet-btn"
                    onClick={() => signOut()}
                  >
                    <i className="fa fa-sign-out-alt" />
                    SignOut
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ConnectModal
        showConnectModal={showConnectModal}
        setShowConnectModal={setShowConnectModal}
      />
    </div>
  );
}

export default Navbar;
