import React from "react";
import "./styles.css";

const StakeCard = ({
  staked,
  holded,
  selectedItems,
  handleOnChange,
  item,
  onStake,
  onUnStake,
}) => {
  const traits = item?.traits || item?.attributes || []; // Handle traits dynamically

  return (
    <div className="collections-card stakeHold">
      <div className="card-img">
        <input
          onChange={(e) => handleOnChange(e)}
          value={item?.tokenId}
          name={item?.rarity}
          type="checkbox"
          checked={selectedItems.includes(item?.tokenId)}
        />
        <img src={item.image} alt={item.name} />
      </div>
      <div className="stake-card-details">
        <p>
          {item.name || "Unknown"}
        </p>
        {traits.length > 0 && (
          <div className="nft-traits">
            <h5>Traits:</h5>
            <ul>
              {traits.map((trait, index) => (
                <li key={index}>
                  <strong>{trait.trait_type}:</strong> {trait.value}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default StakeCard;
