import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import StakeCard from "components/Card/stakeCard";
import "./styles.css";

const HoldedNFT = ({
  items = [], // Default to an empty array
  setSelectedHoldedItems,
  selectedHoldedItems,
  contractAddress,
  provider,
  account,
  onStake,
}) => {
  const [page, setPage] = useState(0);
  const itemsPerPage = 15;

  // Handle selection of NFTs
  const handleOnChange = (e, tokenId) => {
    const updatedSelection = selectedHoldedItems.includes(tokenId)
      ? selectedHoldedItems.filter((id) => id !== tokenId)
      : [...selectedHoldedItems, tokenId];

    setSelectedHoldedItems(updatedSelection);
  };

  // Display NFTs for the current page
  const displayNFTs = items
    .slice(page * itemsPerPage, (page + 1) * itemsPerPage)
    .map((item, index) => (
      <StakeCard
        key={index}
        staked={false}
        holded={true}
        selectedItems={selectedHoldedItems}
        handleOnChange={(e) => handleOnChange(e, item.tokenId)}
        item={item}
        onStake={onStake}
      />
    ));

  // Reset page when items change
  useEffect(() => {
    setPage(0);
  }, [items]);

  return (
    <div className="staked-nft">
      <div className="list-page">
        <div className="list-header">
          <h4>Your Owned NFTs</h4>
        </div>
        {items.length > 0 ? (
          <div className="list-page__container">{displayNFTs}</div>
        ) : (
          <p>No NFTs found in your wallet.</p>
        )}
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          pageCount={Math.ceil(items.length / itemsPerPage)}
          onPageChange={({ selected }) => setPage(selected)}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
      </div>
    </div>
  );
};

export default HoldedNFT;
