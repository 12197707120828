import { useWeb3React } from "@web3-react/core";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation } from "react-router";
import { ethers } from "ethers";
import { COLLECTIONS } from "utils";
import HoldedNFT from "components/HoldedNFT/HoldedNFT";
import { getEngineInfo, purchase, fetchOwnedNFTs } from "utils/contracts";
import { NFTMintEngineDetail, NFT } from "utils/typs";
import "./Default.css";

declare global {
  interface Window {
    ethereum?: any;
  }
}

function MintPage() {
  document.body.style.backgroundImage = null;

  const location = useLocation();
  const collectionValue = location.pathname.split("/")[2];
  const [collection, setCollection] = useState(null);
  const [ownedNFTs, setOwnedNFTs] = useState<NFT[]>([]);
  const [selectedHoldedItems, setSelectedHoldedItems] = useState<NFT[]>([]);
  const [loadingNFTs, setLoadingNFTs] = useState(true);
  const [mintEngineDetail, setMintEngineDetail] = useState<NFTMintEngineDetail | null>(null);
  const [loadingMintEngine, setLoadingMintEngine] = useState(true);

  const { library, chainId, account, active } = useWeb3React();
  const [loginStatus, setLoginStatus] = useState(false);

  useEffect(() => {
    for (const _collection of COLLECTIONS) {
      if (_collection.value === collectionValue) {
        setCollection(_collection);
      }
    }
  }, [collectionValue]);

  useEffect(() => {
    const isLoggedIn = account && active && chainId === parseInt(process.env.REACT_APP_NETWORK_ID, 10);
    setLoginStatus(isLoggedIn);
  }, [account, active, chainId]);

  useEffect(() => {
    if (loginStatus && collection?.contractAddress) {
      const fetchNFTs = async () => {
        if (!library || !account) return;

        const provider = new ethers.providers.Web3Provider(library.provider);
        setLoadingNFTs(true);
        const nfts = await fetchOwnedNFTs(account, collection.contractAddress, provider);
        setOwnedNFTs(nfts);
        setLoadingNFTs(false);
      };

      fetchNFTs();
    }
  }, [loginStatus, collection, library]);

  useEffect(() => {
    setLoadingMintEngine(true);
    getEngineInfo(collectionValue).then((nftMintEngineDetail: NFTMintEngineDetail) => {
      setMintEngineDetail(nftMintEngineDetail);
      setLoadingMintEngine(false);
    });
  }, [loginStatus]);

  // 🔹 NEW: Listen for NFT Mint Events
  useEffect(() => {
    if (!library || !account || !collection?.contractAddress) return;

    const provider = new ethers.providers.Web3Provider(library.provider);
    const contract = new ethers.Contract(
      collection.contractAddress,
      [
        "event Transfer(address indexed from, address indexed to, uint256 indexed tokenId)"
      ],
      provider
    );

    const handleMintEvent = async (from, to, tokenId) => {
      if (to.toLowerCase() === account.toLowerCase()) {
        console.log(`🎉 New NFT Minted! Token ID: ${tokenId.toString()}`);

        // Fetch the latest owned NFTs
        const updatedNFTs = await fetchOwnedNFTs(account, collection.contractAddress, provider);
        setOwnedNFTs(updatedNFTs);
      }
    };

    // Attach event listener
    contract.on("Transfer", handleMintEvent);

    // Cleanup on unmount
    return () => {
      contract.off("Transfer", handleMintEvent);
    };
  }, [library, account, collection]);

  const [quantity, setQuantity] = useState(1);

  const sum = (method: string) => {
    if (method === "increase" && quantity < 10) setQuantity(quantity + 1);
    if (method === "decrease" && quantity > 1) setQuantity(quantity - 1);
  };

  const onMint = async () => {
    if (!loginStatus) {
      toast.error("Please connect your wallet correctly!");
      return;
    }

    if (quantity <= 0) {
      toast.error("Mint count should be greater than 0");
      return;
    }

    const loadToastId = toast.loading("Minting, please wait...");
    try {
      const bSuccess = await purchase(
        collectionValue,
        chainId,
        library.getSigner(),
        account,
        quantity,
        false
      );

      if (bSuccess) {
        getEngineInfo(collectionValue).then((nftMintEngineDetail: NFTMintEngineDetail) => {
          setMintEngineDetail(nftMintEngineDetail);
        });
        toast.success("Mint Successful!");
      } else {
        toast.error("Mint Failed!");
      }
    } catch (error) {
      toast.error("Mint Failed!");
    }
    toast.dismiss(loadToastId);
  };

  return (
    <div className="mint-section">
      <h1 className="mint-heading">{collection?.name}</h1>

      <div className="neoh_fn_mintbox">
        <div className="mint_left">
          <div className="mint_title">
            <span>
              {mintEngineDetail?.totalSupply < 1001 && collectionValue === "PixelBuddiesNFT"
                ? "Presale"
                : "Public"}{" "}
              Mint is Live
            </span>
          </div>

          <div className="mint_list">
            <ul>
              <li>
                <div className="item">
                  <h4>Price</h4>
                  <h3>{mintEngineDetail ? mintEngineDetail.mintCost : "Loading..."} MTV</h3>
                </div>
              </li>
              <li>
                <div className="item">
                  <h4>Remaining</h4>
                  <h3>
                    {mintEngineDetail
                      ? mintEngineDetail.maxSupply - mintEngineDetail.totalSupply
                      : "Loading..."}{" "}
                    / {mintEngineDetail?.maxSupply || "Loading..."}
                  </h3>
                </div>
              </li>
              <li>
                <div className="item">
                  <h4>Quantity</h4>
                  <div className="qnt">
                    <span className="decrease" onClick={() => sum("decrease")}>
                      -
                    </span>
                    <span className="summ">{quantity}</span>
                    <span className="increase" onClick={() => sum("increase")}>
                      +
                    </span>
                  </div>
                </div>
              </li>
              <li>
                <div className="item">
                  <h4>Total Price</h4>
                  <h3>{mintEngineDetail ? mintEngineDetail.mintCost * quantity : "Loading..."} MTV + GAS</h3>
                </div>
              </li>
            </ul>
          </div>

          <div className="mint_desc">
          <button onClick={() => onMint()} className="neoh_fn_button">
              <span className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.0"
                  width="512.000000pt"
                  height="512.000000pt"
                  viewBox="0 0 512.000000 512.000000"
                  preserveAspectRatio="xMidYMid meet"
                  className="fn__svg replaced-svg"
                >
                  <g
                    transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                    stroke="none"
                  >
                    <path d="M2148 5097 c-72 -31 -117 -66 -222 -178 -63 -67 -91 -105 -106 -145 -38 -102 -20 -206 48 -286 69 -80 69 -79 34 -72 -85 17 -67 33 -639 -539 -298 -297 -540 -546 -549 -566 -11 -22 -15 -49 -11 -78 5 -49 9 -50 -69 17 -86 74 -207 89 -309 40 -74 -35 -254 -218 -291 -295 -36 -74 -40 -175 -10 -240 28 -60 1274 -1308 1341 -1343 73 -38 168 -37 250 2 75 36 252 211 291 289 53 104 39 223 -36 311 -67 78 -66 76 -26 69 19 -3 48 -2 63 2 18 4 100 78 231 208 l202 202 379 -379 380 -379 -35 -42 c-95 -117 -116 -286 -49 -414 17 -32 202 -225 602 -628 550 -554 581 -585 648 -615 63 -29 80 -33 160 -33 81 0 97 3 160 33 62 29 94 57 267 231 172 172 201 206 230 267 30 62 33 78 33 159 0 77 -5 99 -30 155 -26 60 -68 104 -569 609 -298 299 -567 565 -598 591 -143 121 -334 125 -484 11 l-51 -39 -379 379 -379 379 202 202 c130 131 204 213 208 231 4 15 5 44 2 63 l-6 35 32 -26 c74 -60 94 -73 140 -90 62 -23 146 -16 214 19 78 39 253 216 289 291 39 82 40 177 2 250 -35 67 -1283 1313 -1342 1340 -65 29 -151 30 -218 2z"></path>
                  </g>
                </svg>
              </span>
              <span className="text">Mint Now</span>
            </button>
          </div>
        </div>
      </div>

      {loadingNFTs ? (
        <div className="owned-nfts">
          <h2>Loading your NFTs...</h2>
        </div>
      ) : (
        <HoldedNFT
          items={ownedNFTs}
          setSelectedHoldedItems={setSelectedHoldedItems}
          selectedHoldedItems={selectedHoldedItems}
          contractAddress={collection?.contractAddress || ""}
          provider={library ? new ethers.providers.Web3Provider(library.provider) : null}
          account={account || ""}
          onStake={() => {}}
        />
      )}
    </div>
  );
}

export default MintPage;
