import "./styles.css";
import StakedNFT from "components/StakedNFT/StakedNFT";
import HoldedNFT from "components/HoldedNFT/HoldedNFT";
import axios from "axios";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useWeb3React } from "@web3-react/core";
import { COLLECTIONS } from "utils";
import { getStakingInfo, onHarvest, onNFTStake, onNFTUnStake, fetchOwnedNFTs } from "utils/contracts";
import toast from "react-hot-toast";
import { NFTStakingInfo } from "utils/typs";

const StakePage = () => {
  const location = useLocation();
  const collectionValue = location.pathname.split("/")[2];
  const [collection, setCollection] = useState(null);
  const { library, account, active, chainId } = useWeb3React();
  const [ownedNFTs, setOwnedNFTs] = useState([]);
  const [selectedHoldedItems, setSelectedHoldedItems] = useState([]);
  const [stakedItems, setStakedItems] = useState([]);
  const [nftStakingInfo, setNftStakingInfo] = useState<NFTStakingInfo | null>(null);
  const [loginStatus, setLoginStatus] = useState(false);

  useEffect(() => {
    for (const _collection of COLLECTIONS) {
      if (_collection.value === collectionValue) {
        setCollection(_collection);
      }
    }
  }, [collectionValue]);

  useEffect(() => {
    const isLoggedIn = account && active && chainId === parseInt(process.env.REACT_APP_NETWORK_ID, 10);
    setLoginStatus(isLoggedIn);
  }, [account, active, chainId]);

  useEffect(() => {
    if (loginStatus && collection?.contractAddress) {
      const fetchNFTs = async () => {
        if (!library || !account) return;

        const provider = new ethers.providers.Web3Provider(library.provider);
        const nfts = await fetchOwnedNFTs(account, collection.contractAddress, provider);
        setOwnedNFTs(nfts);
      };

      fetchNFTs();
    }
  }, [loginStatus, collection, library]);

  const handleStake = async (selectedItems) => {
    if (!account || !collection || !library || selectedItems.length === 0) return;

    const provider = new ethers.providers.Web3Provider(library.provider);
    const signer = provider.getSigner();

    // **Calculate rarityList from selected NFTs**
    const rarityList = selectedItems.map((tokenId) => {
      const nft = ownedNFTs.find((nft) => nft.tokenId === tokenId);
      return nft ? nft.rarity || 0 : 0; // Default rarity to 0 if not found
    });

    // **Generate amountList for staking contract**
    let amountList = [0, 0, 0, 0, 0]; // Assuming 5 rarity levels
    rarityList.forEach((rarity) => {
      if (rarity >= 0 && rarity < amountList.length) {
        amountList[rarity]++;
      }
    });

    try {
      await onNFTStake(collection, account, chainId, signer, selectedItems, rarityList, amountList);
      toast.success("NFTs staked successfully!");
      setSelectedHoldedItems([]);
    } catch (error) {
      console.error("Staking error:", error);
      toast.error("Failed to stake NFTs.");
    }
  };

  const handleUnstake = async (selectedItems) => {
    if (!account || !collection || !library || selectedItems.length === 0) return;

    const provider = new ethers.providers.Web3Provider(library.provider);
    const signer = provider.getSigner();

    // **Generate amountList for unstaking**
    let amountList = [0, 0, 0, 0, 0]; // Assuming 5 rarity levels
    selectedItems.forEach((tokenId) => {
      const nft = stakedItems.find((nft) => nft.tokenId === tokenId);
      if (nft && nft.rarity >= 0 && nft.rarity < amountList.length) {
        amountList[nft.rarity]++;
      }
    });

    try {
      await onNFTUnStake(collection, account, chainId, signer, selectedItems, amountList);
      toast.success("NFTs unstaked successfully!");
    } catch (error) {
      console.error("Unstaking error:", error);
      toast.error("Failed to unstake NFTs.");
    }
  };

  const handleClaim = async () => {
    if (!account || !collection || !library) return;
    const provider = new ethers.providers.Web3Provider(library.provider);
    const signer = provider.getSigner();

    try {
      await onHarvest(collection, account, chainId, signer);
      toast.success("Rewards claimed successfully!");
    } catch (error) {
      console.error("Claiming error:", error);
      toast.error("Failed to claim rewards.");
    }
  };

  return (
    <div className="stake-page-style">
      <h2>My Staked {collection?.name}</h2>

      <div className="stacked-details-container">
        <div className="stacked-details">
          <p>{nftStakingInfo?.totalEarned?.toFixed(2) || "0.00"} BUD</p>
          <span>Claimable</span>
        </div>
        <div className="stacked-details">
          <p>{stakedItems?.length || 0}</p>
          <span>Staked</span>
        </div>
        <div className="stacked-details">
          <p>{ownedNFTs?.length || 0}</p>
          <span>Not Staked</span>
        </div>
      </div>

      <div className="stake-actions">
        <button className="stake-hold-claim connect-wallet-btn" onClick={handleClaim}>
          Claim Rewards
        </button>
      </div>

      <div className="staked-page">
        <StakedNFT
          selectedStakedItems={[]}
          setSelectedStakedItems={() => {}}
          items={stakedItems}
          onUnStake={handleUnstake}
        />
        
        <HoldedNFT
          items={ownedNFTs}
          setSelectedHoldedItems={setSelectedHoldedItems}
          selectedHoldedItems={selectedHoldedItems}
          contractAddress={collection?.contractAddress || ""}
          provider={library ? new ethers.providers.Web3Provider(library.provider) : null}
          account={account || ""}
          onStake={handleStake}
        />
      </div>
    </div>
  );
};

export default StakePage;
